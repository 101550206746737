import './App.css';
import Background from './components/images/card-background.png'
import React, {useEffect, useRef, useState} from 'react'
import emailjs from '@emailjs/browser'
import {ReactComponent as Kitchen} from './components/svg/kitchen.svg'
import Logo from './components/images/logo.png'
import {ReactComponent as Close} from './components/svg/close.svg'
import Shard from './components/images/shard.png'
import {useCookies} from 'react-cookie'

const end = new Date('2023-01-04 23:59:59');

function App() {
  const [ selected, setSelected] = useState(null)
  const [ sent, setSent ] = useState(false)
  const [ emailAddress, setEmailAddress ] = useState('')
  const [ terms, setTerms ] = useState(false)
  const [ isMobile, setIsMobile ] = useState(false)
  const [ viewTerms, setViewTerms ] = useState(false)
  const [ sending, setSending ] = useState(false)
  const tableRef = useRef(null)
  const boardRef = useRef(null)
  const [ cookies, setCookie ] = useCookies(['played'])
  const [ error, setError ] = useState(false)


  const resizeTable = () => {
    if (tableRef.current && boardRef.current && boardRef.current.clientHeight > 0) {
      tableRef.current.style.height = `${boardRef.current.clientHeight - ((boardRef.current.clientHeight * 0.028751) * 2)}px`
    }
  }
  useEffect(() => {
    const checkScreen = () => {
      // eslint-disable-next-line no-restricted-globals
      if (window.innerWidth >= 1300) {
        setIsMobile(false)
      } else {
        setIsMobile(true)
      }
      resizeTable()
    }
    if (window) {
      checkScreen();
      resizeTable();
      window.addEventListener('orientationchange', checkScreen)
      window.addEventListener('resize', checkScreen)
      window.addEventListener('scroll', resizeTable)
    }
    return () => {
      if (window) {
        window.removeEventListener('orientationchange', checkScreen)
        window.removeEventListener('resize', checkScreen)
        window.removeEventListener('scroll', resizeTable)
      }
    }
  }, [])

  const hasExpired = () => {
    const now = new Date()
    return end < now
  }

  const handleSubmit = () => {
    let played = []
    if (cookies.played && cookies.played !== '') {
      played = cookies.played
    }
    if (emailAddress !== '' && played.findIndex(i => i.toLowerCase() === emailAddress.toLowerCase()) === -1) {
      setSending(true)
      played.push(emailAddress)
      emailjs.send('service_tzobrig', 'template_nggh1pr', {
        guess: selected,
        email_address: emailAddress,
        optOut: terms ? 'YES' : 'NO'
      }, 'AwiCzKQwFsSz1mazy').then((resp) => {
        if (resp.status === 200) {
          setSent(true);
          setError(false);
          setSending(false);
          setCookie('played', JSON.stringify(played), {
            path: '/',
            expires: new Date('2023-01-04 00:00:00'),
            secure: true,
            sameSite: true
          })
        }
      });
    } else {
      setError(true)
    }
  }
  const handleSelection = (letter, num) => {
    setTimeout(() => {
      setSelected(`${String.fromCharCode(letter + 97).toUpperCase()}${num + 1}`)
    }, 1000)
  }
  return (
    <div className="App">
      <div className="intro">
        <a href="https://kitchensoho.com">&lt; Back to website</a>
        <Kitchen />
        <h1>Welcome to</h1>
        <img src={Logo} alt="Spot The Bauble" />
        {!isMobile && (
          <>
            <p>
              Forget the Winter World Cup, today's where the action really hots up! And what's more, you don't need to
              be a footie ace to walk away with the prize, you just need a moment of inspiration... and a little bit of luck!
            </p>
            <h2>Rules</h2>
            <p>
              Simply roll your cursor over the grid opposite and click where you think the missing bauble could be. Good luck!
            </p>
            <h2>THE PRIZE</h2>
            <p>
              Enjoy a slice of the high-life with a chef's tasting menu for two at the Oblix restaurant situated on the
              32nd floor of the Shard!
            </p>
          </>
        )}
        {isMobile && (
          <>
            <p>
              Forget the Winter World Cup, today’s where the action really hots up! Simply roll your cursor over the
              grid and click where you think the missing bauble could be. Get it right and you’ll be off to The Shard
              to enjoy a chef’s tasting menu for two! Good luck.
            </p>
          </>
        )}
      </div>
      <div className="game">
        <div className="wrapper">
          <div className="numbers">
            {Array.from(Array(12), (e, i) => (
              <span key={i}>{i + 1}</span>
            ))}
          </div>
          <div>
            <div className="letters">
              {Array.from(Array(8), (e, i) => (
                <span key={i}>{String.fromCharCode(i + 97).toUpperCase()}</span>
              ))}
            </div>
            <div className="board" ref={boardRef}>
              <img src={Background} alt="Merry Christmas" />
              <table className="grid" cellPadding={0} cellSpacing={0} border={0} ref={tableRef}>
                <tbody>
                {Array.from(Array(8), (e, i) => {
                  return <tr className="row" key={i}>
                    {Array.from(Array(12), (e, j) => {
                      return <td className={`${selected && selected === String.fromCharCode(i + 97).toUpperCase() + (j + 1) ? 'square selected' : 'square'}`} key={j} onClick={() => { handleSelection(i,j) }} />
                    })}
                  </tr>
                })}
                </tbody>
              </table>
              {selected && (
                <div className="overlay">
                  <div className="modal">
                    <img src={Shard} alt="" className="shard" />
                    <span className="close" onClick={() => {
                      setSent(false)
                      setSelected(null)
                    }}><Close /></span>
                    {!hasExpired() && (
                      <>
                        <h3>{selected} Good Choice!</h3>
                        <div className="form">
                          {!sent && (
                            <>
                              <p>
                                Simply fill in your email address below and we'll let you know if you're our lucky winner!
                              </p>
                              {error && (
                                <p style={{ color: 'red' }}>Sorry the email address has been used or is invalid.</p>
                              )}
                              <label htmlFor="emailAddress">
                                <span>Email</span>
                                <input type="email" value={emailAddress} id="emailAddress"
                                       onChange={(evt) => setEmailAddress(evt.target.value)}/>
                              </label>
                              <label htmlFor={"terms"}>
                                <input type="checkbox" id="terms" value="1" checked={terms} onChange={(evt) => setTerms(!!evt.currentTarget.checked)} />
                                <p>Click box to opt out of any further communication by email (after this competition). </p>
                              </label>
                              <div className="view-terms">
                                <p onClick={() => setViewTerms(!viewTerms)}><strong>Terms and conditions apply – click to expand</strong></p>
                                {viewTerms && (
                                  <p>
                                    The winner will be announced on the 04/01/23. No cash alternative to the prize.
                                    If multiple people select the correct square, a draw will be made to find one winner.
                                    Maximum one entry per person, no one affiliated with the game can enter &ndash; and
                                    strictly no reindeer, elves, or snowmen are allowed to play (sorry!).
                                    Have fun and good luck!
                                  </p>
                                )}
                              </div>
                            </>
                          )}
                          {sent && (
                            <div>
                              <p>
                                <strong>Thanks for playing! We'll let you know if you're our lucky winner soon.</strong>
                              </p>
                              <p>
                                Click below to find out more about the wonderful things we make at Kitchen
                              </p>
                              <a href="https://kitchensoho.com" className="exit">Find Out More</a>
                            </div>
                          )}
                          <div>
                            {!sending && !sent && (
                              <button type="button" className={`${sent ? 'disabled' : ''}`} disabled={sent} onClick={handleSubmit}>
                                {!sent && <>Submit</>}
                                {sent && <>Sent</>}
                              </button>
                            )}

                          </div>
                        </div>
                      </>
                    )}
                    {hasExpired() && (
                      <>
                        <p>
                          Sorry the game's over now. Don't worry, there's still loads of great things to see on our website.
                        </p>
                        <a href="https://kitchensoho.com" className="exit">Find Out More</a>
                      </>
                    )}
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default App;
